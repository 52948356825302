:root {
  --panelTitleTop: 9px;
  --panelTitleRight: 15px;
}

.panelTitle {
  position: relative;
}
  
.panelTitle::after {
	content: "\f107";
	color: #333;
	top: var(--panelTitleTop);
  right: var(--panelTitleRight);
  font-size: 20px;
	position: absolute;
  font-family: "FontAwesome";
  transform: rotate(-90deg);
  transition: 100ms;
}

.panelTitleToggled::after {
  content: "\f107";
  color: #333;
	top: var(--panelTitleTop);
  right: var(--panelTitleRight);
  font-size: 20px;
	position: absolute;
  font-family: "FontAwesome";
  transform: rotate(0deg);
  transition: 100ms;
}

.contentCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
/*  */
.shadowBox {
  -webkit-box-shadow: 2px 1px 5px 0px rgb(99, 100, 100);
  -moz-box-shadow: 2px 1px 5px 0px rgb(99, 100, 100);
  box-shadow: 2px 1px 5px 0px rgb(99, 100, 100);
}

.container {
  display: block;
  background: rgba(247, 247, 247, 0.856);
  height: 100%;
  overflow-y: scroll;
  width: 100%;
}

.header {
  background: rgb(239, 239, 239);
  padding: 20px;
  margin: 0;
}

.header > .title {
  line-height: 1px;
  color: #58666C;
}

.header > .description {
  line-height: 1px;
  color: #58666C;
}

.card {
  margin: 20px 2% 0 2%;
  border: solid rgb(239, 239, 239);
  padding: 0;
  border-radius: 5px;
}

.cardHeader {
  width: 100%;
  background-color: rgb(239, 239, 239);
  height: 3rem;
  border: none;
}

.cardTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #58666C;
  height: 3rem;
  margin: 0 0 0 20px;
}

.cardBody {
  background-color: white;
  border: none;
  padding: 20px 0 50px 0;
}

.body {
  background: rgb(239, 239, 239);
  padding: 20px;
  margin: 20px 0 0 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.form {
  width: 100%;
  padding: 10px;
}

.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.formTitle {
  color: #58666C;
}