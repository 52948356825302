html,
body {
  box-sizing: border-box;
  min-height: 100%;
  width: 100%;
  position: fixed;
  overflow-x: none;
  overflow-y: none;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', 'Open Sans', Helvetica, sans-serif;
}

#root {
  box-sizing: border-box;
  min-height: 100%;
  width: 100%;
  position: fixed;
  overflow-x: none;
  overflow-y: none;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  height: 100%;
  width: 100%;
  overflow: scroll;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.content {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  position: fixed;
  overflow-x: none;
  overflow-y: none;
  margin: 0;
  padding: 0;
}
.btn-back-radius{
  height: 35px!important;
  width: 160px;
  margin-top: 20px;
  margin-right: 5px;
  border-radius: 50px!important;
  cursor: pointer;
  transition: width, border-radius;
  transition-timing-function: linear;
  transition-duration: 0.5s;
  color: #ffffff !important;
  background-color: #3a3f51;
  border: none;
}

.flow-y{
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.btn-back-arrow-left{
margin-right: 10px;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  /* STYLES GO HERE */
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width : 321px) {
  /* STYLES GO HERE */
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width : 320px) {
  /* STYLES GO HERE */
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  /* STYLES GO HERE */
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
  /* STYLES GO HERE */
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
  /* STYLES GO HERE */
}

/* Desktops and laptops ----------- */
@media only screen and (min-width : 1224px) {
  /* STYLES GO HERE */
}

/* Large screens ----------- */
@media only screen and (min-width : 1824px) {
  /* STYLES GO HERE */
}

/* iPhone 5 (portrait &amp; landscape)----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 568px) {
  /* STYLES GO HERE */
}

/* iPhone 5 (landscape)----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 568px) and (orientation : landscape) {
  /* STYLES GO HERE */
}

/* iPhone 5 (portrait)----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 568px) and (orientation : portrait) {

  /* STYLES GO HERE */
  h1 {
    font-size: 1em;
  }

  footer {
    font-size: 0.6em;
  }
}