.contentCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.shadowBox {
  -webkit-box-shadow: 2px 1px 5px 0px rgb(99, 100, 100);
  -moz-box-shadow: 2px 1px 5px 0px rgb(99, 100, 100);
  box-shadow: 2px 1px 5px 0px rgb(99, 100, 100);
}

.container {
  display: block;
  background: rgba(247, 247, 247, 0.856);
  height: 100%;
  overflow-y: scroll;
}

.body {
  background: rgb(255, 255, 255);
  padding: 20px;
  margin: 10px 0 0 0;
  min-height: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.search-user {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 1rem;
  margin-left: 3rem;
}

.search-user label {
  margin: 0;
  margin-right: 1rem;
}

.search-user input {
  width: 25%;
}

.tableHeader {
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: row;
}

.tableTitle {
  width: 40%;
  height: auto;
}

.tableTitle>h2 {
  color: #58666C;
}

.tableOption {
  width: 60%;
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0 0 10px 0;
}

table {
  width: 90%;
  border-collapse: collapse;
  margin-bottom: 100px;
}

th,
td {
  text-align: center;
}

.tableButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
}

.margin {
  margin: 5px 0 0 0;
}

tr,
td,
th {
  color: #58666C;
  border-bottom: 1pt solid #58666C;
  padding: 10px 0 10px 0;
}

.tableHeaderMin {
  height: 76vh;
  overflow-y: auto;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

  /* STYLES GO HERE */
  .tableButton {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 100%;
  }
}


/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  .mobile-none {
    display: none;
  }

  .tableHeader {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
  }

  .search-user {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 1rem;
  }

  .search-user label {
    margin: 0;
    margin-right: 1rem;
  }

  .search-user input {
    width: 70%;
  }
}

/* iPhone 5 (portrait &amp; landscape)----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 568px) {
  .tableButton button {
    margin-bottom: 1rem;
  }

  .table td,
  .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    white-space: pre-line;
    overflow-wrap: break-word;
    max-width: 6rem;
  }

  .tableTitle h2 {
    font-size: 1.5em;
  }

  .mobile-none {
    display: none;
  }

  .tableHeader {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
  }

  .search-user {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 1rem;
  }

  .search-user label {
    margin: 0;
    margin-right: 1rem;
  }

  .search-user input {
    width: 70%;
  }

  .body {
    width: 100% !important;
  }

  /* STYLES GO HERE */
}