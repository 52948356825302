footer{
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: #efefef;
    padding-right: 30px;
}
.version{
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.textVersion{
    color: #58666C;
    font-size: 15px;
    padding: 5px;
    font-weight: 700;
}