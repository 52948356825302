.tableEmpresa {
    overflow: auto;
    height: 100vh
}

.w-table {
    width: 90%;
}

.MuiTableContainer-root {
    height: 54vh;
}

.MuiPaper-root.MuiPaper-rounded {
    box-shadow: 0px 0px 0px 0px rgb(99, 100, 100) !important;
}

.MuiTablePagination-root {
    width: 100% !important;
}
.search{
    width: 70%;
    margin-bottom: 30px;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 568px) {
    .body {
        align-items: flex-start !important;
        width: 53% !important;
        margin: 0 !important;
    }

    .w-table {
        width: 100%;
    }

    .MuiTableContainer-root {
        height: 100% !important;
    }

    .MuiTable-root.historico-empresa-table {
        width: 100% !important;
        height: 100vh;
        overflow: auto;
        margin-bottom: 8rem;
    }

    .MuiTablePagination-root {
        height: 14vh;
    }

    .historico-empresa-table {
        margin-bottom: 1rem;
    }

    .MuiTablePagination-root {
        text-align: left;
        display: flex;
        justify-content: left;
        /* align-items: center; */
        align-items: start;
        /* STYLES GO HERE */
        position: fixed;
        /* top: 0; */
        bottom: 0;
        background-color: white;
        height: 8vh;
        width: 100%;
        margin: 0;
        padding: 0;
    }
}